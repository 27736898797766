import * as React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

interface MainContentProps {
  title: string
  imageData?: { source_url: string; alt_text: string }
  text?: { sub_paragraph: string; sub_title?: string }[]
  pageImage?: { source_url: string }
}
const MainContent: React.FC<MainContentProps> = ({ title, text, imageData, children, pageImage }) => {
  const { i18n } = useTranslation()
  const paragraph = text?.map((p, index) => {
    return (
      <div key={index} className={styles.paragraph}>
        {p.sub_title && <h3 className={styles.paragraphHeader}>{p.sub_title}</h3>}
        <p>{p.sub_paragraph}</p>
      </div>
    )
  })

  //scaleinfo
  if (title === 'Felix klimatskala & klimatmärkning') {
    return (
      <div
        className={styles.scaleMainWrapper}
        style={{
          color: 'white',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.25)), url(${imageData?.source_url})`
        }}
      >
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.paragraphWrapper}>{paragraph}</div>
        {children}
      </div>
    )
  }
  //about
  if (imageData) {
    return (
      <>
        <div className={styles.mainWrapper} style={{ backgroundImage: `url(${imageData?.source_url})` }}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.paragraphWrapper}>{paragraph}</div>
          <a href="/felix-klimatskala-klimatmarkning" className={styles.link}>
            {i18n.t('products.read_more')}
          </a>

          {/* <div className={styles.imageWrapper}> */}
          <img loading="lazy" src={pageImage?.source_url} alt="Tomater i hög" className={styles.mainImage} />
          {/* </div> */}
        </div>
      </>
    )
  }
  //faq
  if (title === 'Vanliga frågor') {
    return (
      <div className={styles.mainWrapper} style={{ color: 'white' }}>
        <h1 className={styles.title}>{title}</h1>
        {children}
      </div>
    )
  }
  //budget
  return (
    <div className={styles.mainWrapper} style={{ color: 'white' }}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.paragraphWrapper}>{paragraph}</div>
      {children}
    </div>
  )
}

export default MainContent
