import * as React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import IndexLayout from '../layouts'
import Faq from '../components/Faq/Faq'
import { FaqAcf } from '../models/Faq'
import MainContent from '../components/MainContent/MainContent'
import { Helmet } from 'react-helmet'

interface FaqPage {
  main_title: string
  sub_texts?: [{ sub_paragraph: string }]
}

interface PageTemplateProps {
  data: {
    allWordpressPage: {
      edges: [
        {
          node: { acf: FaqPage }
        }
      ]
    }
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  if (!data.allWordpressPage.edges) return null

  const { main_title }: FaqPage = get(data, 'allWordpressPage.edges[0].node.acf')
  const { question_group }: FaqAcf = get(data, 'allWordpressPage.edges[0].node.acf')

  const meta = {
    title: 'Vanliga Frågor | Klimatbutiken',
    description: 'Vanliga frågor och svar om Klimatbutiken'
  }

  return (
    <IndexLayout background="black">
      <Helmet title={meta.title}>
        <meta property="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="description" content={meta.description} />
      </Helmet>
      <MainContent title={main_title} />
      <Faq arr={question_group} />
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query ($slug: String!) {
    allWordpressPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          acf {
            main_title
            question_group {
              question
              answer
            }
          }
        }
      }
    }
  }
`
