import * as React from 'react'
import styles from './styles.module.scss'
import { QuestionGroup } from '../../models/Faq'

export interface FaqProps {
  arr: QuestionGroup[]
}

const Faq: React.FC<FaqProps> = ({ arr }) => {
  return (
    <>
      <ul className={styles.faqArray}>
        {arr.map((item, i) => {
          return (
            <li key={i}>
              <h2>{item.question}</h2>
              {item.answer && <div dangerouslySetInnerHTML={{ __html: item.answer }} className={styles.answerText} />}
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default Faq
